import React from "react";

const ErrorCodes = ({ errorMessage }) => {
  return (
    <div className="form-error--container">
      {errorMessage && <div className="form-error--text">{errorMessage}</div>}
    </div>
  );
};

export default ErrorCodes;
