import React, { createContext, useEffect, useState } from "react";

import * as Utils from "./utils";

import { navigate } from "gatsby";

import { useQueryParam, NumberParam } from "use-query-params";
import { stringify } from "query-string";

import { formFieldTacker } from "../../../tracker";

import {
  FormDataErrorsType,
  FormDataType,
  FormsContextType,
  FormsContextProviderType,
} from "./types";

export {
  FormDataErrorsType,
  FormDataType,
  FormsContextType,
  FormsContextProviderType,
};

export const FormsContext: any = createContext({});

export const FormContextProvider: React.FC<FormsContextType> = ({
  children,
}): React.ReactElement<FormsContextProviderType> => {
  const [formData, setFormData] = useState<FormDataType>(
    Utils.getFormDataInit()
  );

  const [formErrors, setFormErrors] = useState<FormDataErrorsType>(null);
  const [formLoading, setFormLoading] = useState(false);

  const formPage = useQueryParam("page", NumberParam)[0];
  const forms = ["contact-info", "session-details", "confirmation"];

  useEffect(() => {
    if (
      formData.hasPreferredExpert === "false" &&
      (formData.alternateExpert !== "none" ||
        formData.preferredExpert !== "none")
    ) {
      setFormData({
        ...formData,
        preferredExpert: "none",
        alternateExpert: "none",
      });
    } else if (
      formData.hasPreferredExpert === "true" &&
      (formData.alternateExpert === "none" ||
        formData.preferredExpert === "none")
    ) {
      setFormData({
        ...formData,
        preferredExpert: "",
        alternateExpert: "",
      });
    }
  }, [formData]);

  const updateFormData = (field, data) => {
    let newFormData = { ...formData };

    const trackingPage = formPage ? formPage + 2 : 1;
    formFieldTacker(field, formData, trackingPage);

    // Removes keys with blank values
    if (Array.isArray(field)) {
      field.forEach((f) => {
        newFormData = { ...newFormData, [f]: data };
        if (data === "" && newFormData[f]) delete newFormData[f];
      });
    } else {
      // Removes keys with blank values
      if (data === "" && newFormData[field]) delete newFormData[field];
      else newFormData[field] = data;
    }

    setFormData(newFormData);

    // removes error code when user starts typing
    if (formErrors && formErrors.errors && formErrors.errors.length) {
      setFormErrors({
        errors: formErrors.errors.filter((err) => field !== err.path[0]),
      });
    }
  };

  const setForm = (formPage: number) => {
    const data = Utils.extractValueFromFormData(formData);
    const pageUrl = `/schedule-a-session/${forms[formPage]}/`;
    const queryString = `${stringify(data)}&page=${formPage}`;

    navigate(`${pageUrl}?${queryString}`);
  };

  const validate = () => {
    setFormLoading(true);

    const data = Utils.extractValueFromFormData(formData);
    const queryString = `${stringify(data)}&page=${formPage}`;
    const pageUrl = `/schedule-a-session/${forms[formPage]}/`;

    navigate(`${pageUrl}?${queryString}`, { replace: true });

    return Utils.doFormValidation(formData, formPage)
      .then(() => next())
      .catch((errData) => {
        setFormErrors(errData);
        setFormLoading(false);
      });
  };

  const next = () => {
    const newPage =
      formPage + 1 < forms.length ? formPage + 1 : forms.length - 1;
    setForm(newPage);
  };

  const submit = (): Promise<FormDataType> => Utils.doFormSubmission(formData);

  const providerData = {
    next,
    setForm,
    formData,
    updateFormData,
    submit,
    formLoading,
    validate,
    formErrors,
  };

  return (
    <FormsContext.Provider value={providerData}>
      {children}
    </FormsContext.Provider>
  );
};
