import React, { useEffect, FC, useState } from "react";
import { Isi } from "../Isi";

import "./styles.scss";

const IsiLayer: FC<{ hideLayer: boolean }> = ({ hideLayer }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (show) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }
  }, [show]);

  return (
    <div
      className={`isi__layer ${show ? "show" : ""} ${
        hideLayer ? "hide-layer" : ""
      }`}
    >
      <Isi />
      <div className="isi__layer--show-hide-link--container">
        <a
          href="#"
          className="isi__layer--show-hide-link"
          data-analytics-event-action="Expanded"
          data-analytics-event-subject="Meeting Request"
          data-analytics-event-category="Important Safety Information"
          data-analytics-event-label="Oxbryta Important Safety Information"
          onClick={(e) => {
            e.preventDefault();
            setShow(!show);
          }}
        >
          {!show && "More"}
          {show && "Less"}
        </a>
        <svg
          className="isi__layer--show-hide-link--arrow"
          width="14px"
          height="8px"
          viewBox="0 0 14 8"
          version="1.1"
        >
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="indications-/-float-/-mobile"
              transform="translate(-347.000000, -32.000000)"
              fill="#119CBD"
              stroke="#119CBD"
              strokeWidth="0.4"
            >
              <g
                id="Group-49-Copy"
                transform="translate(353.500000, 35.500000) scale(-1, -1) translate(-353.500000, -35.500000) translate(347.000000, 32.000000)"
              >
                <path
                  d="M12.5672279,0.217177774 C12.366762,-0.0385411265 12.0119832,-0.0738120503 11.7708669,0.140021923 L6.3492778,4.96113396 L0.927688674,0.140021923 C0.687947985,-0.0730708907 0.331105768,-0.037801848 0.13132774,0.217177774 C-0.0691380841,0.472896674 -0.0360745382,0.853521673 0.202973065,1.06661449 L5.98697731,6.20958546 C6.09168971,6.30290574 6.22051021,6.3492 6.34933952,6.3492 C6.47816884,6.3492 6.60698052,6.30290762 6.71170174,6.20958546 L12.495706,1.06661449 C12.7347589,0.853521673 12.7671382,0.472896674 12.5673513,0.217177774 L12.5672279,0.217177774 Z"
                  id="Fill-48"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default IsiLayer;
