import React, { FC, useContext } from "react";
import Select from "react-select";

import { FormsContext, FormsContextProviderType } from "../FormsContext";
import { InputContainer } from "../Input";

import DropdownIndicator from "./DropDownIndicator";

import check from "../../../images/check.svg";

import "./styles.scss";

type options = {
  value: string;
  label: string;
};

type SelectType = {
  options: options[];
  name: string;
  label?: string;
  subLabel?: string;
  placeHolder?: string;
  styles?: any;
  customComponents?: any;
  errorMessage?: string;
  onChange?: (item) => void;
};

const SelectInput: FC<SelectType> = ({
  options,
  label,
  name,
  subLabel,
  placeHolder,
  styles,
  customComponents,
  errorMessage,
  onChange,
}) => {
  const formContext: FormsContextProviderType = useContext(FormsContext);
  const formData = formContext.formData;

  const valueInit = options.filter((option) => option.value === formData[name]);

  let value =
    (valueInit.length ? valueInit[0] : false) ||
    (formData && formData[name]) ||
    "";

  // https://react-select.com/styles#provided-styles-and-state

  const components = {
    ...customComponents,
    DropdownIndicator,
  };

  return (
    <InputContainer
      errorMessage={errorMessage}
      name={name}
      label={label}
      subLabel={subLabel}
    >
      <Select
        className="form-input--select"
        options={options}
        value={value}
        placeholder={placeHolder}
        defaultValue={value}
        isSearchable={false}
        components={components}
        styles={{ ...baseStyles, ...styles }}
        onChange={(option) => {
          onChange && onChange(option);
          formContext.updateFormData(name, option);
        }}
      />
    </InputContainer>
  );
};

export default SelectInput;

const menuHeight = "464px";

const baseStyles = {
  indicatorSeparator: () => ({}),
  valueContainer: (provided) => ({
    ...provided,
    display: "flex",
    flexWrap: "nowrap",
  }),
  menuList: () => ({
    maxHeight: menuHeight,
    overflow: "scroll",
  }),
  indicatorsContainer: () => ({
    margin: "3px 19px 0px 19px",
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "0px",
    top: "35px",
    background: "#F6F6F6",
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? "#979797" : "",
    color: state.isSelected ? "white" : "",
    backgroundImage: state.isSelected ? "url(" + check + ")" : "",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "3px center",
    padding: "3px 0px",
    paddingLeft: "28px",
    cursor: "pointer",
    "&:hover": {
      background: "#979797",
      color: "white",
    },
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: 0,
    border: "1px solid #979797",
    paddingLeft: "16px",
    height: "42px",
    cursor: "pointer",
  }),
};
