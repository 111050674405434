import { useEffect, useState } from "react";

export const useOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        return setIntersecting(entry.isIntersecting);
      },
      { rootMargin: "0px", threshold: 0 }
    );

    if (ref.current) observer.observe(ref.current);
    return () => {
      if (ref.current) observer.disconnect();
    };
  }, []);

  return isIntersecting;
};
