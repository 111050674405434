import React, { FC } from "react";
import "./button.scss";

type ButtonProps = {
  text: string | React.ReactElement;
  analytics: Analytics;
  type?: "button" | "submit";
  white?: boolean;
  onClick?: () => void;
  link?: string;
  disabled?: boolean;
};

export type Analytics = {
  subject: string;
  action: string;
  category: string;
  label?: string;
};

export const Button: FC<ButtonProps> = ({
  link,
  onClick,
  text,
  type,
  white,
  disabled = false,
  analytics,
}) => {
  const analyticsProps = {
    "data-analytics-event-subject": analytics.subject,
    "data-analytics-event-action": analytics.action,
    "data-analytics-event-category": analytics.category,
    "data-analytics-event-label": analytics.label ? analytics.label : link,
  };

  const classes = ["button", white && "button--white"]
    .filter((x) => x)
    .join(" ");
  return (
    <>
      {link && (
        <a
          onClick={(e) => {
            e.preventDefault();
            onClick();
          }}
          href={link}
          className={classes}
          {...analyticsProps}
        >
          {text}
        </a>
      )}
      {!link && (
        <button
          className={classes}
          type={type || "button"}
          {...analyticsProps}
          onClick={(e) => {
            e.preventDefault();
            !disabled && onClick();
          }}
        >
          {text}
        </button>
      )}
    </>
  );
};
