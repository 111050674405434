import React from "react";
import downArrow from "../../../images/down-arrow.svg";

const DropdownIndicator = (state) => {
  const style = {
    transition: "transform .25s",
    transform: state.selectProps.menuIsOpen ? "scale(1, -1)" : null,
  };
  return (
    <>
      <img style={style} src={downArrow} />
    </>
  );
};

export default DropdownIndicator;
