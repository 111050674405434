import { FormDataErrorsType, FormDataType, FormDataErrorType } from "./types";

import { useQueryParam, StringParam } from "use-query-params";

import { formSubmitTracker, formErrorTracker } from "../../../tracker";

const pageSchema = [
  [
    "email",
    "firstName",
    "lastName",
    "designation",
    "specialty",
    "phone",
    "zip",
    "npi",
  ],
  [
    "presentationTopic",
    "preferredExpert",
    "alternateExpert",
    "hasPreferredExpert",
    "preferredDate",
    "alternateDate",
    "preferredTime",
    "alternateTime",
    "acceptTerms",
    "optInMarketing",
  ],
];

const requestOptionsDefaults = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
};

export const doFormSubmission = (formData): Promise<FormDataType> => {
  const cleanData = extractValueFromFormData(formData);

  const resquestOptions = {
    ...requestOptionsDefaults,
    body: JSON.stringify(cleanData),
  };

  formSubmitTracker(formData);

  return new Promise((res, rej): void => {
    fetch(`/api/meetings/request`, resquestOptions)
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.errors) rej(responseData);
        else res(responseData);
      });
  });
};

export const doFormValidation = (formData, page = 0): Promise<FormDataType> => {
  const cleanData = extractValueFromFormData(formData);

  const resquestOptions = {
    ...requestOptionsDefaults,
    body: JSON.stringify(cleanData),
  };

  return new Promise((res, rej): void => {
    fetch(`/api/meetings/request?draft=1`, resquestOptions)
      .then((response) => {
        if (response.status === 400) {
          return response.json();
        } else if (response.status === 204) {
          return true;
        } else {
          return new Error();
        }
      })
      .then((responseData) => {
        if (responseData.errors) {
          const pageValid = checkValidationAgainstPageSchema(
            responseData,
            page,
            pageSchema
          );

          if (pageValid) res(responseData);
          else rej(responseData);
        } else {
          res(responseData);
        }
      });
  });
};

export const extractValueFromFormData = (
  formData: FormDataType
): FormDataType => {
  // Extracts value property from form data objects
  const formDataCopy = { ...formData };
  for (const data in formDataCopy) {
    if (typeof formDataCopy[data] === "object") {
      if (formDataCopy[data].value) {
        formDataCopy[data] = formDataCopy[data].value;
      } else if (formDataCopy[data].name) {
        formDataCopy[data] = formDataCopy[data].name;
      }
    }
    if (typeof formDataCopy[data] === "string") {
      if (formDataCopy[data] === "true") {
        formDataCopy[data] = true;
      }
      if (formDataCopy[data] === "false") {
        formDataCopy[data] = false;
      }
    }
  }
  return formDataCopy;
};

const checkValidationAgainstPageSchema = (
  errorData: FormDataErrorsType,
  page,
  formSchema
): Boolean => {
  const validationInSchema = errorData.errors.filter(
    (err: FormDataErrorType) => {
      if (!err.path[0]) return false;
      return formSchema[page].includes(err.path[0]);
    }
  );

  if (validationInSchema.length) {
    // Tracks errors in current page schema
    // { page } param is the front end page, NOT the validation schema page, thus 2 is added.
    formErrorTracker(validationInSchema, page + 2);
  }

  return validationInSchema.length === 0;
};

export const getFormDataInit = (): FormDataType => {
  const email = useQueryParam("email", StringParam)[0] || "";
  const firstName = useQueryParam("firstName", StringParam)[0] || "";
  const lastName = useQueryParam("lastName", StringParam)[0] || "";
  const designation = useQueryParam("designation", StringParam)[0] || "";
  const specialty = useQueryParam("specialty", StringParam)[0] || "";
  const phone = useQueryParam("phone", StringParam)[0] || "";
  const zip = useQueryParam("zip", StringParam)[0] || "";
  const npi = useQueryParam("npi", StringParam)[0] || "";
  const preferredTime = useQueryParam("preferredTime", StringParam)[0] || "";
  const alternateTime = useQueryParam("alternateTime", StringParam)[0] || "";
  const preferredDate = useQueryParam("preferredDate", StringParam)[0] || "";
  const alternateDate = useQueryParam("alternateDate", StringParam)[0] || "";
  const presentationTopic =
    useQueryParam("presentationTopic", StringParam)[0] || "";
  const preferredExpert =
    useQueryParam("preferredExpert", StringParam)[0] || "";
  const alternateExpert =
    useQueryParam("alternateExpert", StringParam)[0] || "";
  const hasPreferredExpert =
    useQueryParam("hasPreferredExpert", StringParam)[0] || "";
  const optInMarketing =
    useQueryParam("optInMarketing", StringParam)[0] || "false";
  const acceptTerms = "true";

  const data: FormDataType = {
    email,
    firstName,
    lastName,
    designation,
    specialty,
    phone,
    zip,
    npi,
    presentationTopic,
    preferredExpert,
    alternateDate,
    alternateExpert,
    preferredDate,
    alternateTime,
    hasPreferredExpert,
    acceptTerms,
    optInMarketing,
    preferredTime,
  };
  return data;
};
