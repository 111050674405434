import React, { PropsWithChildren, useState, useRef } from "react";

import { useOnScreen } from "../Utils";

import { Footer } from "../Footer";
import { Header } from "../Header";
import { Isi } from "../Isi";
import { OneColumn } from "../Flexbox";

import IsiLayer from "../IsiLayer";

import * as CONSTS from "../../constants";

import "../../styles/base.scss";
import "./page.scss";

type PageProps = PropsWithChildren<{
  copyrightYear?: string;
  prcNumber?: string;
}>;

type PageComponent = React.FC<PageProps>;

export const Page: PageComponent = ({
  children,
  prcNumber = CONSTS.DEFAULT_PRC_NUMBER,
}) => {
  const ref = useRef(null);
  const isiOnScreen = useOnScreen(ref);

  return (
    <>
      <Header brand="oxbryta" />
      <article className="page">
        <OneColumn>{children}</OneColumn>
      </article>
      <IsiLayer hideLayer={isiOnScreen} />
      <div ref={ref}>
        <Isi />
      </div>
      <Footer prcNumber={prcNumber} />
    </>
  );
};
