import React, { FC, useContext, useEffect } from "react";

import { FormsContext, FormsContextProviderType } from "../FormsContext";
import ErrorCodes from "../FormErrors";

import { InputType, InputGroupType, InputContainerType } from "./types";

import "./styles.scss";

const Input: FC<InputType> = ({
  label,
  type,
  name,
  infobubble,
  errorMessage,
}) => {
  const formContext: FormsContextProviderType = useContext(FormsContext);
  const formData = formContext.formData;
  const val = (formData && formData[name]) || "";

  return (
    <InputContainer errorMessage={errorMessage} name={name} label={label}>
      {infobubble && infobubble}
      <input
        id={name}
        name={name}
        value={val}
        type={type}
        className="form-input--input"
        onChange={(e) => formContext.updateFormData(name, e.target.value)}
      />
    </InputContainer>
  );
};

export default Input;

export const InputContainer: FC<InputContainerType> = ({
  children,
  name,
  label,
  subLabel,
  errorMessage,
  className = "",
}) => {
  const formContext: FormsContextProviderType = useContext(FormsContext);

  let errors: any = formContext.formErrors?.errors?.filter((err) => {
    return err.path[0] === name;
  });

  return (
    <div
      className={`${className} form-input--container ${
        errors && errors[0] ? "form-error" : ""
      }`}
    >
      <label className="form-input--label" htmlFor={name}>
        {label && (
          <div className="form-input--label-text">
            <strong>{label}</strong>
          </div>
        )}
        {subLabel && <p className="body-text--small">{subLabel}</p>}
        {children}
      </label>
      {errors && errors.length != 0 && (
        <ErrorCodes errorMessage={errorMessage} />
      )}
    </div>
  );
};

export const InputGroup: FC<InputGroupType> = ({
  children,
  label,
  subLabel,
}) => {
  return (
    <div className={`form-input--input-group form-input--container`}>
      <div className="form-input--label-text">{label}</div>
      <div className="form-fields--note body-text--small">{subLabel}</div>
      <div className={`form-input--input-group__items`}>
        {React.Children.map(children, (child) => {
          return <div className="form-input--input-group__item">{child}</div>;
        })}
      </div>
    </div>
  );
};
